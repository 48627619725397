const campaigns = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get(`/campaigns`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        getDetail: async (campaignId) => {
            try {
                const {data} = await apiClient.get(`/campaigns/${campaignId}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (name,
                                subject_template,
                                email_template,
                                role_pattern,
                                company_ids) => {
            try {
                const {data} = await apiClient.post(`/campaigns`, {
                    name,
                    subject_template,
                    email_template,
                    role_pattern,
                    company_ids
                });
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        updateSelectedOpening: async (campaignId, openingsArray) => {
            try {
                const {data} = await apiClient.put(`/campaigns/${campaignId}/openings`, {
                    openings_selected: openingsArray
                });
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        updateSelectedContacts: async (campaignId, contactsArray) => {
            try {
                const {data} = await apiClient.put(`/campaigns/${campaignId}/contacts`, {
                    contacts_selected: contactsArray
                });
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        sendEmail: async (campaignId) => {
            try {
                const {data} = await apiClient.patch(`/campaigns/${campaignId}`, {
                    current_step: 'email_waiting'
                });
                return data;
            } catch (error) {
                handleError(error);
            }
        },
    }
}
export default campaigns;